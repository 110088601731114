import { Link } from '@remix-run/react'
import { type PropsWithChildren } from 'react'

import type { button } from '~/components/Button'
import { buttonStyles } from '~/components/Button'
import { Logo } from '~/components/Logo'

export const Wireframe = ({ children }: PropsWithChildren) => {
  return (
    <div className='relative flex h-dvh flex-col items-center justify-center bg-accent-10 text-center'>
      <Logo className='scale-150' />
      <div className='mt-8 min-w-[100dvw] border border-gray-200 bg-white p-12 shadow sm:min-w-[600px]'>{children}</div>
    </div>
  )
}

export const WireframeButton = ({
  to,
  children,
  intent
}: PropsWithChildren<{ to: string; intent?: NonNullable<Parameters<typeof button>[0]>['color'] }>) => {
  return (
    <Link to={to} className={buttonStyles({ color: intent })}>
      {children}
    </Link>
  )
}
